/**
 * @name Object_TestApplication_AudioVideoInformation
 * @summary This object consists of storing,initializing the params in store and have get and edit  function.
 **/
var Object_TestApplication_AudioVideoInformation = {

    /**
    * @name GetData
    * @param {objParams} objParams passes objParams
    * @param {callback} fnCallback callback function
    * @summary GetData for AudioVideoInformation
    */
    GetData: (objParams, fnCallback) => {
        let arrParams = [
            {
                "URL": "API/Object/TestApplication/GetAudioVideoInformation",
                "Params": objParams
            }
        ];
        return new Promise((resolve, reject) => {
            ArcadixFetchData.Execute(arrParams, (objReturn) => {
                if (objReturn) {
                    resolve(objReturn);
                }
                else {
                    resolve(null);
                }
            });
        });
    },

    /**
    * @name AddData
    * @param {objParams} objParams passes objParams
    * @param {callback} fnCallback callback function
    * @summary AddData for AudioVideoInformation
    */
    AddData: (objParams, cIsPreviewMode = "N", fnCallback) => {
        let arrParams = [
            {
                "URL": "API/Object/TestApplication/AudioVideoInformation/AddData",
                "Params": objParams
            }
        ];
        return new Promise((resolve, reject) => {
            if (cIsPreviewMode == "Y") {
                ArcadixFetchData.Execute(arrParams, (objReturn) => {
                    if (objReturn) {
                        resolve(objReturn);
                    }
                    else {
                        resolve(null);
                    }
                });
            }
            else {
                // add to cookie/ async storage
                fnCallback();
            }
        });
    }
};

export default Object_TestApplication_AudioVideoInformation;
